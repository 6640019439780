import { IUsersSearchState } from '@models';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { adapter, NAMESPACE } from '../reducers/users-search';

export const selectUsersSearchState =
  createFeatureSelector<IUsersSearchState>(NAMESPACE);

const { selectAll, selectTotal: selectTotalLocal } = adapter.getSelectors(
  selectUsersSearchState
);

export const selectTotal = createSelector(
  selectUsersSearchState,
  (state: IUsersSearchState) => state.total
);
export const selectIsLoading = createSelector(
  selectUsersSearchState,
  (state: IUsersSearchState) => state.isLoading
);
export const selectError = createSelector(
  selectUsersSearchState,
  (state: IUsersSearchState) => state.error
);
export const selectTerm = createSelector(
  selectUsersSearchState,
  (state: IUsersSearchState) => state.term
);
export const selectShowMore = createSelector(
  selectUsersSearchState,
  (state: IUsersSearchState) => state.showMore
);
export const selectUsersSearch = selectAll;
export const selectCount = selectTotalLocal;
